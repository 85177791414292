<template>
  <Toast />
  <ConfirmDialog />
  <ul
    class="list-none p-0 m-0 align-items-center font-medium mb-3"
    v-show="false"
  >
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">VENTAS</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        ><strong>NUEVA VENTA: </strong>{{ fechaActual(fecha_venta) }} >
        <strong>SUCURSAL:</strong> {{ nombre_sucursal }}
      </span>
    </li>
  </ul>
  <div v-if="cargandoComponente">
    <div class="grid" v-if="permitirVenta">
      <div class="col-12 md:col-8">
        <div class="card">
          <div class="p-fluid formgrid grid p-fondo">
            <div class="field col-12 md:col-5 mt-2">
              <span><strong>BUSCAR CLIENTE | NOMBRE | CI/NIT:</strong></span>
              <div class="p-inputgroup">
                <AutoComplete
                  :dropdown="false"
                  field="nombre"
                  v-model="clienteSelect"
                  :suggestions="clientes"
                  @complete="buscarCliente($event)"
                  placeholder="Buscar Cliente"
                  v-tooltip.top="'Buscar Cliente por Nombre'"
                >
                  <template #item="slotProps">
                    <div>
                      <strong>{{ slotProps.item.nombre }}</strong
                      ><span><strong> - Nit:</strong></span>
                      {{ slotProps.item.ci_nit }}
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-user"
                  class="p-button-info"
                  v-tooltip.top="'Nuevo Cliente'"
                  @click="activarCliente"
                />
              </div>
              <div
                class="p-inputgroup mt-1"
                v-if="
                  clienteSelect != null &&
                  clienteSelect.id != null &&
                  clienteSelect.tipo_cliente_id != 1
                "
              >
                <span>
                  <strong class="mr-1 text-900">TIPO CLIENTE:</strong>
                  <span class="p-tag p-tag-rounded p-tag-warning">
                    {{ clienteSelect.nombre_tipo_cliente ?? "" }}
                  </span>
                </span>
              </div>
            </div>
            <div class="field col-12 md:col-3 mt-2">
              <span><strong>CI/NIT:</strong></span>
              <div class="p-inputgroup">
                <InputText
                  v-tooltip.top="'Nit a Facturar'"
                  v-model="nit"
                  placeholder="0"
                  :readonly="!facturar"
                  @keyup.enter="buscarClientePorNit"
                />
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-search"
                  class="p-button-warning"
                  v-tooltip.top="'Buscar NIT'"
                  @click="buscarClientePorNit"
                  :disabled="!facturar"
                />
                <Button
                  v-if="facturar && !this.razon_social"
                  icon="pi pi-id-card"
                  class="p-button-info"
                  v-tooltip.top="'Registrar NIT'"
                  @click="activarClienteFactura"
                />
              </div>
            </div>
            <div class="field col-12 md:col-4 mt-2">
              <span><strong>RAZ&Oacute;N SOCIAL:</strong></span>
              <div class="p-inputgroup">
                <Textarea v-model="razon_social" rows="1" readonly autoResize />
              </div>
            </div>
          </div>
          <div class="grid mt-2 mb-0 p-0">
            <div
              class="field col-12 md:col-5 mb-0"
              v-if="'Venta ConMedico' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span><strong>MÉDICO:</strong></span>
                  <Checkbox
                    v-model="medico_referencia"
                    value="medico_referencia"
                    v-tooltip.top="'Médico Referencia'"
                  />
                </span>
                <Dropdown
                  v-if="medico_referencia == 'medico_referencia'"
                  v-model="medicoSelected"
                  :options="medicos"
                  placeholder="Seleccione..."
                  optionLabel="nombre_completo"
                  optionValue="id"
                  :filter="true"
                />
                <Button
                  v-if="
                    medico_referencia == 'medico_referencia' &&
                    'Medico Crear' in auth.user.permissions
                  "
                  icon="pi pi-id-card"
                  class="p-button-info"
                  v-tooltip.top="'Nuevo Médico'"
                  @click="activarMedico"
                />
              </div>
            </div>
            <div
              class="field col-12 md:col-4 mb-0"
              v-if="'Venta VenderPaquete' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span v-if="vender_paquete != 'vender_paquete'"
                    ><strong>PAQUETE:</strong></span
                  >
                  <Checkbox
                    v-model="vender_paquete"
                    value="vender_paquete"
                    v-tooltip.top="'Vender Paquete'"
                  />
                </span>
                <Dropdown
                  v-if="vender_paquete == 'vender_paquete'"
                  v-model="paqueteSelected"
                  :options="paqueteproductos"
                  placeholder="Paquete..."
                  optionLabel="nombre"
                  optionValue="id"
                  :filter="true"
                />
                <Button
                  :disabled="!paqueteSelected"
                  v-if="vender_paquete == 'vender_paquete'"
                  icon="pi pi-plus"
                  class="p-button-info"
                  v-tooltip.top="'Agregar Paquete al Detalle'"
                  @click="agregarProductosPaqueteAlDetalle"
                />
              </div>
            </div>
            <div
              class="field col-12 md:col-3 mb-0"
              v-if="'Venta Proforma' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span v-if="!buscar_proforma"
                    ><strong>PROFORMA:</strong></span
                  >
                  <Checkbox
                    v-model="buscar_proforma"
                    :binary="true"
                    v-tooltip.top="'Buscar Proforma'"
                  />
                </span>
                <InputText
                  v-if="buscar_proforma"
                  v-model="proforma_codigo"
                  placeholder="Código Proforma"
                  @keyup.enter="agregarProductosProforma"
                />
                <Button
                  :disabled="!proforma_codigo"
                  v-if="buscar_proforma"
                  icon="pi pi-search"
                  class="p-button-info"
                  v-tooltip.top="'Buscar Proforma'"
                  @click="agregarProductosProforma"
                />
              </div>
            </div>
          </div>
          <Panel
            toggleable
            :collapsed="false"
            class="shadow-2"
            @update:collapsed="actualizarPanelMasFiltros"
          >
            <template #header>
              <div class="p-inputgroup">
                <AutoComplete
                  autofocus
                  field="descripcion"
                  v-model="productoSelecionado"
                  placeholder="Buscar... Nombre de Producto"
                  v-tooltip.top="'Buscar Producto por Nombre'"
                  :suggestions="productosListadoFiltrado"
                  @keyup.enter="buscarProducto($event)"
                >
                  <template #item="slotProps">
                    <div
                      class="flex align-options-center"
                      :class="
                        (slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock[0].cantidad)
                          : 0) > 0
                          ? ''
                          : 'bg-gray-400'
                      "
                    >
                      <div
                        class="text-overflow-ellipsis overflow-hidden p-0"
                        :class="
                          computedBuscarProductoPorSelected ? 'col-6' : 'col-8'
                        "
                        :title="slotProps.item.descripcion"
                      >
                        {{ slotProps.item.descripcion }} <strong>**</strong>
                        <span
                          ><strong>
                            {{
                              soloPrimeraPalabra(
                                slotProps.item.unidad_medida_nombre
                              )
                            }}
                          </strong></span
                        >
                      </div>
                      <div
                        class="col-2 text-overflow-ellipsis overflow-hidden p-0"
                        :title="slotProps.item.fabrica_nombre"
                        style="font-weight: bold"
                      >
                        {{ slotProps.item.fabrica_nombre }}
                      </div>
                      <div
                        class="col-2 p-0 mr-1 text-overflow-ellipsis overflow-hidden"
                        style="background-color: aqua; border-radius: 2px"
                        v-if="
                          computedBuscarProductoPorSelected &&
                          slotProps.item.caso_uso != null &&
                          slotProps.item.caso_uso != ''
                        "
                        v-tooltip.top="slotProps.item.caso_uso"
                      >
                        {{ slotProps.item.caso_uso.substring(0, 15) }}...
                      </div>
                      <div
                        class="col-1 flex align-items-center justify-content-center p-0 mr-1"
                        :class="
                          stockClass(
                            slotProps.item.stock.length > 0
                              ? sinDecimal(slotProps.item.stock[0].cantidad)
                              : 0
                          )
                        "
                      >
                        <strong>{{
                          slotProps.item.stock.length > 0
                            ? sinDecimal(slotProps.item.stock[0].cantidad)
                            : 0
                        }}</strong>
                      </div>
                      <div
                        class="col-1 flex align-items-center justify-content-center p-0"
                      >
                        <strong
                          >Bs.
                          <span v-if="clienteSelect.tipo_cliente_id == 1">{{
                            slotProps.item.stock.length > 0
                              ? slotProps.item.stock[0].ultimo_precio
                              : "-"
                          }}</span>
                          <span
                            v-else-if="clienteSelect.tipo_cliente_id == 2"
                            >{{
                              slotProps.item.stock.length > 0
                                ? slotProps.item.stock[0].pv2
                                : "-"
                            }}</span
                          >
                          <span v-else>{{
                            slotProps.item.stock.length > 0
                              ? slotProps.item.stock[0].pv3
                              : "-"
                          }}</span>
                          <!-- {{
                            slotProps.item.stock.length > 0
                              ? slotProps.item.stock[0].ultimo_precio
                              : "-"
                          }} --></strong
                        >
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  :disabled="!productoSelecionado"
                  icon="pi pi-search"
                  class="p-button-warning"
                  v-tooltip.top="'Buscar Producto'"
                  @click="buscarProducto($event)"
                />
                <Button
                  icon="pi pi-bars"
                  class="p-button-info"
                  v-tooltip.top="'VER RESULTADOS DE BUSQUEDA'"
                  @click="activarModalProductosFiltrados"
                  :disabled="productosListadoFiltrado.length == 0"
                ></Button>
              </div>
            </template>
            <div class="grid">
              <div class="col-12 md:col-4" v-show="false">
                <span><strong>C&Oacute;D BARRA:</strong></span>
                <AutoComplete
                  field="codigo_barra"
                  v-model="codProdSelected"
                  :suggestions="codProdListadoFiltrado"
                  v-tooltip.top="'Código'"
                  placeholder="Código..."
                  @item-select="buscarCodProducto"
                  @complete="buscarCodProducto"
                  @keyup.enter="buscarCodProducto"
                  autocomplete="off"
                />
              </div>
              <div class="col-12 flex flex-align-left">
                <div
                  v-for="filtro of buscarProductoPorListado"
                  :key="filtro.value"
                  class="col-3 mr-1"
                >
                  <div class="p-inputgroup flex align-items-center">
                    <Checkbox
                      v-model="buscarProductoPorSelected"
                      :inputId="filtro.value"
                      name="filtro"
                      :value="filtro.value"
                    />
                    <span class="ml-1 text-900 font-bold" :for="filtro.value">{{
                      filtro.label
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          <DataTable
            v-if="this.mostrarTablaFechasVencimiento == true"
            ref="fechas_vencimiento"
            :value="fechas_vencimiento"
            class="p-datatable-sm mt-2"
            responsiveLayout="scroll"
            scrollHeight="400px"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
            :style="{ border: '1px solid #000000' }"
          >
            <template #empty> Debe seleccionar un producto </template>
            <Column field="nombre_producto" header="DESCRIPCIÓN/PRODUCTO">
              {{ data.nombre_producto ?? "" }}
            </Column>
            <Column class="text-center" field="cantidad" header="EN STOCK">
              <template #body="{ data }">
                <div :class="stockClass(data.cantidad)">
                  {{ convertirNumeroGermanicFormat(data.cantidad) }}
                </div>
              </template>
            </Column>
            <Column
              class="text-right"
              field="ultimo_precio"
              header="PRECIO"
              style="font-weight: bold; font-size: 1.2em"
            >
              <template #body="{ data }">
                {{ convertirNumeroGermanicFormat(data.ultimo_precio ?? 0) }}
              </template>
            </Column>
            <Column
              class="text-center"
              field="fecha_vence"
              header="VENCIEMIENTO"
            >
              <template
                #body="{ data }"
                style="font-weight: bold; font-size: 1.3em"
              >
                {{
                  data.fecha_vence == "1905-03-14"
                    ? "Sin Fecha"
                    : formatDate(data.fecha_vence)
                }}
              </template>
            </Column>
            <Column class="text-center" field="lote" header="LOTE">
              {{ data.lote ?? "0" }}
            </Column>
            <Column header="ACCIONES" style="text-align: center">
              <template #body="slotProps">
                <Button
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-info mr-1"
                  @click="agregarDetalleVenta(slotProps.data)"
                  v-tooltip.top="'Agregar al Detalle'"
                  :disabled="slotProps.data.cantidad <= 0"
                />
              </template>
            </Column>
          </DataTable>
          <DataTable
            ref="dt_venta_detalle"
            class="p-datatable-sm mt-2"
            dataKey="id"
            :value="productosVentas"
            responsiveLayout="scroll"
            scrollHeight="400px"
            editMode="cell"
            @cell-edit-complete="guardandoCambiosCelda"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
          >
            <template #empty>
              <span
                class="flex align-items-center justify-content-center"
                style="
                  background-color: #f8d7da;
                  padding: 5px;
                  border-radius: 5px;
                  color: #721c24;
                "
                >Busca Productos para agregar al Detalle!</span
              ></template
            >
            <Column header="#">
              <template #body="slotProps">
                {{ 1 + slotProps.index }}
              </template>
            </Column>
            <Column field="nombre_producto" header="DESCRIPCIÓN/PRODUCTO">
              <template #body="{ data }">
                <span>
                  <strong>{{ data.nombre_producto }}</strong
                  ><strong> ** </strong>
                  <span v-if="data.nombre_fabrica != null">
                    {{ data.nombre_fabrica }}
                  </span>
                  <span v-else-if="data.fabrica_nombre != null">
                    {{ data.fabrica_nombre }}
                  </span>
                  <span v-else>{{
                    data.producto.fabrica_nombre != null
                      ? data.producto.fabrica_nombre
                      : ""
                  }}</span>
                  <span v-if="mostrarFechaEnDetalle">
                    <strong> ** </strong>
                    <span
                      v-if="data.fecha_vence == '1905-03-14'"
                      style="color: red; font-weight: bold"
                      >S/F</span
                    >
                    <span v-else style="color: green; font-weight: bold">
                      {{ formatDate(data.fecha_vence) }}</span
                    >
                  </span>
                </span>
              </template>
            </Column>
            <Column field="cantidad" header="STOCK" class="text-center strong">
              <template #body="{ data }">
                <span :class="claseEstado(data)">
                  <strong> {{ sinDecimal(data.cantidad) }}</strong>
                </span>
              </template>
            </Column>
            <Column
              class="text-right strong bg-green-200"
              field="cantidad_venta"
              header="CANTIDAD"
            >
              <template #body="slotProps">
                {{ slotProps.data.cantidad_venta }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  input-class="flex-row-reverse"
                  locale="de-DE"
                  :min="1"
                />
              </template>
            </Column>
            <Column
              class="text-right strong"
              field="ultimo_precio"
              header="PRECIO"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.ultimo_precio)
                }}
              </template>
              <template
                #editor="{ data, field }"
                v-if="'Venta EditarPrecio' in this.auth.user.permissions"
              >
                <InputNumber
                  v-model="data[field]"
                  :inputClass="'text-right'"
                  :min="1"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column
              v-if="aplica_descuento"
              class="flex-row-reverse strong bg-green-200"
              field="descuento_neto"
              header="DESC."
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.descuento_neto)
                }}
              </template>
              <template #editor="slotProps">
                <InputNumber
                  :inputClass="'text-right'"
                  :min="0"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                  v-model="slotProps.data.descuento_neto"
                />
              </template>
            </Column>
            <Column class="text-right" field="total" header="TOTAL">
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(
                    slotProps.data.ultimo_precio *
                      slotProps.data.cantidad_venta -
                      slotProps.data.descuento_neto
                  )
                }}
              </template>
            </Column>
            <Column>
              <template #header>
                <i class="pi pi-check"></i>
              </template>
              <template #body="slotProps">
                <Checkbox
                  v-model="slotProps.data.entregado"
                  value="entregado"
                  :disabled="slotProps.data.cantidad_venta == 0"
                  v-tooltip.top="'Entregado'"
                />
              </template>
            </Column>
            <Column class="strong" style="min-width: 20px">
              <template #header>
                <i class="pi pi-trash"></i>
              </template>
              <template #body="slotProps">
                <Button
                  @click="quitarProductoDetalle(slotProps)"
                  v-tooltip.top="'Quitar Producto'"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="card">
          <h4><strong>TOTALES DE VENTAS:</strong></h4>
          <div class="p-fluid formgrid grid">
            <table class="field col-12 md:col-12">
              <tr>
                <td>
                  <strong>SUB TOTAL:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(subtotal) }} Bs.
                </td>
              </tr>
              <tr v-if="aplica_descuento">
                <td>
                  <strong>DESCUENTO:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(descuento) }} Bs.
                </td>
              </tr>
              <tr>
                <td>
                  <h4><strong>TOTAL VENTA:</strong></h4>
                </td>
                <td class="text-right">
                  <h2>{{ convertirNumeroGermanicFormat(total_venta) }} Bs.</h2>
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>A CUENTA:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(a_cuenta + monto_pago) }} Bs.
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>SALDO:</strong>
                </td>
                <td class="text-right">
                  {{
                    convertirNumeroGermanicFormat(
                      total_venta - a_cuenta - monto_pago
                    )
                  }}
                  Bs.
                </td>
              </tr>
            </table>
            <div class="field col-12 md:col-12" v-if="facturar" v-show="false">
              <span><strong>ACTIVIDAD ECONOMICA:</strong></span>
              <div class="p-inputgroup">
                <Dropdown
                  v-model="actividad_economica_selected"
                  :options="actividades_economicas"
                  option-label="descripcion"
                  option-value="codigo"
                  placeholder="Seleccione una actividad economica"
                  @change="cambiarActividadEconomica"
                />
              </div>
            </div>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <template v-if="permitir_facturar">
                  <Checkbox
                    class="mr-1"
                    value="facturar"
                    v-model="facturar"
                    :binary="true"
                    v-tooltip.top="'Facturar'"
                    :disabled="
                      'Venta Facturar' in auth.user.permissions == false ||
                      total_venta == 0
                    "
                  /><span class="p-tag p-tag-rounded p-tag-danger">ENVIAR</span>
                </template>
                <Checkbox
                  class="ml-2 mr-1"
                  :disabled="
                    'Venta Descuentos' in auth.user.permissions == false ||
                    descuento > 0 ||
                    total_venta == 0
                  "
                  :binary="true"
                  v-model="aplica_descuento"
                  v-tooltip.top="'Aplicar Descuento'"
                />
                <span class="p-tag p-tag-rounded p-tag-warning">DESCUENTO</span>
                <Checkbox
                  class="ml-2 mr-1"
                  :binary="true"
                  v-tooltip.top="'Observación'"
                  v-model="observacion_check"
                />
                <span class="p-tag p-tag-rounded p-tag-info">OBSERVACIÓN</span>
              </div>
            </div>
            <div class="field col-12 md:col-12" v-if="observacion_check">
              <Textarea
                v-model="observacion"
                rows="3"
                autoResize
                placeholder="Glosa/observación"
              />
            </div>
            <template v-if="aplica_descuento">
              <table class="field col-12 md:col-12">
                <tr v-show="true">
                  <td>
                    <strong>DESC. NETO :</strong>
                  </td>
                  <td class="text-right">
                    <InputNumber
                      v-model="descuento"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="subtotal"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento neto"
                      @keyup="calcular_agregar_des_total_venta"
                      :disabled="aplica_descuento"
                    />
                  </td>
                  <td class="text-right">
                    <Button
                      icon="pi pi-check"
                      class="p-button-outlined p-button-secondary"
                      v-tooltip.top="'Descuento neto'"
                      @click="calcular_agregar_des_total_venta"
                      :disabled="aplica_descuento"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>DESCUENTO %:</strong>
                  </td>
                  <td class="text-right strong">
                    <InputNumber
                      v-model="desc_venta_porcentaje"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="100"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento %"
                      @keyup="calcular_agregar_des_total_venta_porcentaje"
                    />
                  </td>
                  <td class="text-right">
                    <Button
                      v-tooltip.top="'Descuento %'"
                      icon="pi pi-check"
                      class="p-button-outlined p-button-secondary"
                      @click="calcular_agregar_des_total_venta_porcentaje"
                    />
                  </td>
                </tr>
              </table>
            </template>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <template v-if="'Venta Credito' in auth.user.permissions">
                  <Checkbox
                    class="mr-1"
                    v-model="a_credito_check"
                    :binary="true"
                    v-tooltip.top="'Seleccionar tipo de pago'"
                    :disabled="
                      'Venta Credito' in auth.user.permissions == false ||
                      total_venta == 0
                    "
                  /><span
                    :class="
                      'p-tag p-tag-rounded' +
                      (total_venta == 0
                        ? ' bg-black-alpha-20'
                        : ' p-tag-primary')
                    "
                    >A CRÉDITO</span
                  >
                </template>
                <template v-if="'Venta M.Pagos' in auth.user.permissions">
                  <Checkbox
                    class="ml-2 mr-1"
                    v-model="metodo_pago_check"
                    :binary="true"
                    :disabled="
                      'Venta M.Pagos' in auth.user.permissions == false ||
                      total_venta == 0
                    "
                    v-tooltip.top="'Seleccionar Método de Pago'"
                  /><span
                    :class="
                      'p-tag p-tag-rounded' +
                      (total_venta == 0
                        ? ' bg-black-alpha-20'
                        : ' p-tag-success')
                    "
                    >PAGOS QR</span
                  >
                </template>
                <template v-if="'Proforma Crear' in auth.user.permissions">
                  <Checkbox
                    class="mr-1 ml-1"
                    value="proforma"
                    :binary="true"
                    v-tooltip.top="'Crear como proforma'"
                    v-model="proforma"
                    :disabled="total_venta == 0 || facturar == 1"
                  />
                  <span
                    :class="
                      'p-tag p-tag-rounded' +
                      (total_venta == 0
                        ? ' bg-black-alpha-20'
                        : ' p-tag-danger')
                    "
                    >PROFORMA</span
                  >
                </template>
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <table style="width: 100%">
                <!-- <tr v-if="a_credito_check">
                  <td>
                    <strong>TIPO DE PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      v-model="tipo_pagoSelect"
                      :options="tipo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>D&Iacute;AS CR&Eacute;DITO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="dias_credito"
                      :min="1"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      showButtons
                    />
                  </td>
                </tr> -->
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>EFECTIVO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="a_cuenta"
                      :min="0"
                      :max="total_venta - monto_pago - 1"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :minFractionDigits="2"
                      :useGrouping="false"
                      placeholder="a cuenta efectivo"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pago_check">
                  <td>
                    <strong>M&Eacute;TODO PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      v-model="metodo_pagoSelect"
                      :options="metodo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect == 6 && qr_cobros != null">
                  <td>
                    <strong>QR COBROS: </strong>
                  </td>
                  <td>
                    <Image
                      :src="'data:image/png;base64, ' + qr_cobros"
                      width="150"
                      preview
                    ></Image>
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect == 2 && facturar == 1">
                  <td>
                    <strong>N&Uacute;MERO DE TARJETA: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="nro_tarjeta"
                      :min="8"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      placeholder="Ingrese el número de tarjeta"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect != 1">
                  <td>
                    <strong>MONTO PAGO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="monto_pago"
                      :min="0"
                      :max="total_venta - a_cuenta"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      :minFractionDigits="2"
                      showButtons
                      placeholder="monto pago transferencias"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div class="field col-12 md:col-6 hidden">
              <span><strong>Cotizaci&oacute;n</strong></span>
              <Checkbox class="ml-3" value="cotizar" v-model="cotizar" />
            </div>
            <table class="field col-12 md:col-12">
              <tr>
                <td>
                  <strong>RECIBIDO:</strong>
                </td>
                <td class="text-right">
                  <InputNumber
                    v-model="efectivo"
                    :min="total_venta - monto_pago - a_cuenta"
                    locale="de-DE"
                    input-class="text-right"
                    :minFractionDigits="2"
                    inputId="locale-german"
                    :disabled="
                      metodo_pagoSelect != 1 ? true : false || a_credito_check
                    "
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    <h2>CAMBIO:</h2>
                  </strong>
                </td>
                <td class="text-right p-invalid">
                  <strong>
                    <h1>{{ convertirNumeroGermanicFormat(cambio) }} Bs.</h1>
                  </strong>
                </td>
              </tr>
            </table>
            <div class="col-12 md:col-12 flex justify-content-end">
              <Button
                :label="
                  proforma == 1
                    ? 'REGISTRAR PROFORMA'
                    : facturar == 1
                    ? 'REGISTRAR FACTURA'
                    : 'REGISTRAR VENTA'
                "
                icon="pi pi-save"
                v-tooltip.top="
                  proforma == 1
                    ? 'Registrar Proforma'
                    : facturar == 1
                    ? 'Registrar Factura'
                    : 'Registrar Venta'
                "
                class="p-button-info p-button-lg"
                @click="guardarVenta"
                :disabled="total_venta == 0"
                :loading="guardando"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-else>
      <Message class="col-12" :severity="'info'" :closable="false">
        <div class="flex align-items-center justify-content-center">
          <span> PARA REALIZAR VENTAS DEBE ABRIR LA CAJA </span>
          <Button
            label="APERTURAR CAJA"
            class="p-button-success p-button-lg ml-3"
            icon="pi pi-lock-open"
            v-tooltip.top="'Abrir Caja'"
            @click="this.$router.push({ name: 'caja' })"
          />
        </div>
      </Message>
    </div>
  </div>
  <div v-else>
    <div class="grid">
      <h3 class="col-12">Verificando caja ...</h3>
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        class="col-12"
      />
    </div>
  </div>
  <ClienteCreate
    :show="clienteModal"
    :cliente="cliente"
    @closeModal="cerrarModalCliente"
    @actualizarListado="cargarClientesListado"
  >
  </ClienteCreate>
  <ClienteFactura
    :show="clienteFacturaModal"
    :cliente_factura="cliente_factura"
    @closeModal="cerrarModalClienteFactura"
    @actualizarListado="buscarClientePorNit"
  >
  </ClienteFactura>
  <MedicoCreate
    :show="medicoModal"
    :medico="medico"
    @closeModal="cerraModalMedico"
    @actualizarListado="cargarMedicosListado"
  >
  </MedicoCreate>
  <ProducoListadoModal
    @closeModal="cerrarModalProductoResultados"
    :show="productosResultados"
    :productos="productosFiltrados"
    @agregarProductos="agregarProductos"
  ></ProducoListadoModal>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import ProductService from "@/service/ProductService";
import VentaService from "@/service/VentaService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ClienteFactura from "@/module/clientes/ClienteFactura.vue";
import MedicoCreate from "@/module/medicos/MedicoCreate.vue";
import CajaService from "@/service/CajaService";
import { useAuth } from "@/stores";
import { has } from "lodash";
import TipoPagoService from "@/service/TipoPagoService";
import MetodoPagoService from "@/service/MetodoPagoService";
import PaqueteProductoService from "@/service/PaqueteProductoService";
import FacturaService from "@/service/FacturaService";
import MedicoService from "@/service/MedicoService";
import ParametrosSistemaService from "@/service/ParametrosSistemaService";
import ProducoListadoModal from "@/module/productos/ProductoListadoModal.vue";
import EmpresaService from "@/service/EmpresaService"; /*
import LaboratorioService from "@/service/LaboratorioService"; */

export default {
  components: {
    ClienteCreate,
    ClienteFactura,
    ProducoListadoModal,
    MedicoCreate,
  },
  data() {
    return {
      masFiltrosBusqueda: false,
      buscarProductoPorSelected: ["nombre_comercial"],
      buscarProductoPorListado: [
        { label: "Nom. Com.", value: "nombre_comercial" },
        { label: "P Act.", value: "principio_activo" },
        { label: "Cód. Barra", value: "codigo" },
        { label: "Acc. Farm.", value: "accion_farmacologica" },
      ],
      /* busqueda_caso_uso: false, */
      permitir_facturar: false,
      facturar: false,
      cotizar: false,
      guardando: false,
      clienteSelect: null,
      clientes: [],
      razon_social: null,
      nit: 0,
      productoSelecionado: null,
      productosSelecionado: null,
      productosListadoFiltrado: [],
      productosVentas: [],
      stockSucursales: [],
      subtotal: 0,
      descuento: 0,
      total_venta: 0,
      efectivo: 0,
      monto_pago: 0, //monto de pago en efectivo
      clienteModal: false,
      clienteFacturaModal: false,
      cliente: { estado: { label: "Activo", value: 1 } },
      clienteFactura: { estado: { label: "Activo", value: 1 } },
      medicoModal: false,
      medico: { estado: { label: "Activo", value: 1 } },
      fecha_venta: null,
      permitirVenta: false,
      cargandoComponente: false,
      sucursal_id: null,
      caja: null,
      codProdSelected: "",
      codProdListadoFiltrado: [],
      desc_venta_porcentaje: null,
      aplica_descuento: false,
      tipo_pagos: [],
      tipo_pagoSelect: 1,
      metodo_pagos: [],
      metodo_pagoSelect: 1,
      dias_credito: 0,
      a_cuenta: 0,
      saldo: 0,
      observacion: "",
      observacion_check: false,
      a_credito_check: false,
      metodo_pago_check: false,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      vender_paquete: false,
      paqueteSelected: null,
      paqueteproductos: [],
      paqueteproductosListado: [],
      entregado: 0,
      medico_referencia: false,
      medicos: [],
      medicoSelected: null,
      productosResultados: false,
      productosFiltrados: [],
      actividades_economicas: [],
      actividad_economica_selected: null,
      nro_tarjeta: null,
      filtrosRadio: "precio_venta",
      cliente_factura: {},
      nombre_sucursal: null,
      parametros_sistema: {},
      laboratorios: [],
      laboratorioSelected: null,
      proforma: false,
      fechas_vencimiento: [],
      qr_cobros: null,
      mostrarTablaFechasVencimiento: false,
      buscar_proforma: false,
      proforma_codigo: null,
      proforma_id: null,
      imprimirFactura: false,
      imprimirVenta: false,
      permisoActivarModalProductos: false,
      mostrarFechaEnDetalle: false,
    };
  },
  clienteService: null,
  productService: null,
  ventaService: null,
  cajaService: null,
  auth: null,
  tipoPagoService: null,
  metodoPagoService: null,
  paqueteProductoService: null,
  medicoService: null,
  facturaService: null,
  parametrosSistemaService: null,
  empresaService: null /*
  laboratorioService: null, */,
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
    this.productService = new ProductService();
    this.ventaService = new VentaService();
    this.cajaService = new CajaService();
    this.tipoPagoService = new TipoPagoService();
    this.metodoPagoService = new MetodoPagoService();
    this.paqueteProductoService = new PaqueteProductoService();
    this.medicoService = new MedicoService();
    this.facturaService = new FacturaService();
    this.parametrosSistemaService = new ParametrosSistemaService();
    this.empresaService = new EmpresaService();
  },
  mounted() {
    this.datos_venta_general();
  },
  computed: {
    // si mi venta es al credito entonces en efectivo se registra el monto  a cuenta el cambio es el total efectivo - a cuenta
    cambio() {
      if (this.tipo_pagoSelect == 2) {
        return this.efectivo - this.a_cuenta;
      } else {
        return this.efectivo + this.monto_pago - this.total_venta;
      }
    },
    computedBuscarProductoPorSelected() {
      return this.buscarProductoPorSelected.includes("principio_activo");
    },
  },
  methods: {
    soloPrimeraPalabra(texto) {
      if (texto) {
        return texto.split(" ")[0];
      } else {
        return "UNIDAD";
      }
    },
    formatDate(fecha) {
      if (fecha) {
        return fecha.split("-").reverse().join("/");
      }
      return "";
    },
    verificarCasoUso() {
      if (this.buscarProductoPorSelected.includes("principio_activo")) {
        console.log("verificarCasoUso");
        return true;
      } else {
        console.log("no verificarCasoUso");
        return false;
      }
    },
    actualizarPanelMasFiltros(e) {
      this.masFiltrosBusqueda = e;
      if (!this.masFiltrosBusqueda) {
        this.limpiarFiltrosBusquedaProducto();
      }
    },
    limpiarFiltrosBusquedaProducto() {
      this.buscarProductoPorSelected = ["nombre_comercial"];
    },
    cambiarActividadEconomica() {
      let datos = {
        actividad_economica_id: this.actividad_economica_selected,
      };
      this.empresaService.changeActividad(datos).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "ACTIVIDAD ECONOMICA",
            detail: data.message,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error al cambiar la actividad economica",
            life: 3000,
          });
        }
      });
    },
    calcular_agregar_des_total_venta() {
      this.calcular_descuento_venta_porcentaje();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },
    calcular_agregar_des_total_venta_porcentaje() {
      this.calcular_descuento_venta_neto();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },

    cerrarModalProductoResultados() {
      this.productosResultados = false;
    },
    activarModalProductosFiltrados() {
      this.productosResultados = true;
      /* this.productosFiltrados = this.productosListadoFiltrado; */
      //paso tambienn el cliente seleccionado
      this.productosFiltrados = this.productosListadoFiltrado.map(
        (producto) => {
          return {
            ...producto,
            tipo_cliente_id: this.clienteSelect.tipo_cliente_id,
          };
        }
      );
    },
    datos_venta_general() {
      let datos = {
        verificar_caja: true,
        facturar: "facturar",
      };
      this.ventaService.VentaGeneral(datos).then((response) => {
        if (response.success) {
          if (response.datos.caja.length == 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se encontró una caja abierta",
            });
            this.permitirVenta = false;
            this.cargandoComponente = true;

            return;
          }

          this.caja = response.datos.caja[0];
          this.sucursal_id = response.datos.caja[0].sucursal_id;
          this.qr_cobros = response.datos.qr_cobros;
          this.nombre_sucursal = response.datos.caja[0].nombre_sucursal ?? "";
          this.permitirVenta = true;

          this.clientes = response.datos.clientes;
          this.clienteSelect = this.clientes.length ? this.clientes[0] : null;
          this.tipo_pagos = response.datos.tipo_pagos;
          this.metodo_pagos = response.datos.metodo_pagos;
          this.paqueteproductos = response.datos.paquetes;
          this.medicos = response.datos.medicos;
          this.laboratorios = response.datos.laboratorios;

          if (response.datos.facturar) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
          this.actividades_economicas =
            response.datos.actividades_economicas || [];
          if (this.actividades_economicas.length) {
            this.actividad_economica_selected =
              response.datos.actividad_economica_select.codigo;
          }
          this.parametros_sistema = response.datos.parametros_sistema ?? {};
          if (this.parametros_sistema.length > 0) {
            this.imprimirFactura = this.parametros_sistema.some(
              (parametro) => parametro.nombre === "Imprimir Factura"
            );
            this.imprimirVenta = this.parametros_sistema.some(
              (parametro) => parametro.nombre === "Imprimir Venta"
            );
            this.permisoActivarModalProductos = this.parametros_sistema.some(
              (parametro) => parametro.nombre === "permisoActivarModalProductos"
            );
            this.mostrarFechaEnDetalle = this.parametros_sistema.some(
              (parametro) => parametro.nombre === "mostrarFechaEnDetalle"
            );
          }
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 60000,
          });
        }

        this.cargandoComponente = true;
      });
    },
    verificarFacturar() {
      this.parametrosSistemaService
        .verificarPermiso("facturar")
        .then((response) => {
          if (response.permiso) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
        });
    },
    obtenerMedicos() {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
      });
    },
    obtenerPaquetes() {
      this.paqueteProductoService.getPaqueteProductoAll().then((response) => {
        this.paqueteproductos = response.paqueteproductos;
      });
    },
    obtenerTiposPagos() {
      this.tipoPagoService.getAllTipoPagos().then((response) => {
        this.tipo_pagos = response.tipo_pagos;
      });
    },
    obtenerMetodosPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    buscarClientePorNit() {
      //BUSCO EL CLIENTE POR NIT Y SI NO EXISTE LO CREO
      if (this.nit != null) {
        this.clienteService
          .buscarClientePorNit({ nit: this.nit, sucursal_id: this.sucursal_id })
          .then((response) => {
            if (response.cliente != null) {
              this.$toast.add({
                severity: "success",
                summary: "Nit encontrado",
                detail: "Se cargaron los datos del cliente",
                life: 3000,
              });
              /* this.razon_social = response.cliente.nombre_cliente; */
              let nombre_cliente =
                response.cliente.nombre_cliente ?? response.cliente;
              this.razon_social = nombre_cliente ?? "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Cliente no encontrado",
                detail: "Registre los datos del cliente",
                life: 3000,
              });
              /* this.cliente.razon_social = this.razon_social;
              this.cliente.nit = this.nit;
              this.cliente_id = this.clienteSelect.id; */
              this.razon_social = "";
              this.activarClienteFactura();
            }
          });
      }
    },

    aplicar_descuento_productos_detalle() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_porcentaje = this.desc_venta_porcentaje;
        producto.descuento_neto =
          producto.ultimo_precio *
          producto.cantidad_venta *
          (producto.descuento_porcentaje / 100);
      });
      this.calcular_subtotal();
    },
    buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.clientes = [...response.clientes];
          });
      }, 250);
    },
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirVenta = true;
        } else {
          this.permitirVenta = false;
        }

        this.cargandoComponente = true;
      });
    },

    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numero);
    },

    verificarMedico() {
      if (this.medico_referencia) {
        if (this.medicoSelected == "") {
          this.$toast.add({
            severity: "error",
            summary: "Medico",
            detail: "Debe seleccionar un medico",
            life: 60000,
          });
          this.guardando = false;
          return;
        }
      }

      return true;
    },

    guardarVenta() {
      if (this.metodo_pagoSelect == 2 && this.facturar == 1) {
        if (
          this.nro_tarjeta == null ||
          this.nro_tarjeta.toString().length < 8
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Numero de tarjeta",
            detail: "El numero de tarjeta debe tener al menos 8 digitos",
            life: 60000,
          });
          this.guardando = false;
          return;
        }
      }
      let productos = this.productosVentas.filter(
        (producto) =>
          producto.cantidad_venta == 0 || producto.cantidad_venta == null
      );
      if (productos.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: "Revisar productos con cantidad de venta en 0",
          detail:
            "No puede guardar una venta con productos con cantidad de venta en 0",
          life: 60000,
        });
        this.guardando = false;
        return;
      }
      /* <empty string> */
      if (this.clienteSelect == "") {
        this.$toast.add({
          severity: "error",
          summary: "CLIENTE",
          detail: "DEBE SELECCIONAR UN CLIENTE",
          life: 10000,
        });
        this.guardando = false;
        return;
      }
      if (!this.verificarMedico()) {
        this.guardando = false;
        return;
      }

      let datos = {
        razon_social: this.razon_social,
        nit: this.nit,
        cliente_id: this.clienteSelect.id,
        total_cancelar: this.total_venta,
        descuento: this.descuento ? this.descuento : 0,
        efectivo: this.efectivo ? this.efectivo : 0,
        productos: this.productosVentas,
        caja_id: this.caja.id,
        tipo_pago_id: this.tipo_pagoSelect,
        metodo_pago_id: this.metodo_pagoSelect,
        monto_pago: this.monto_pago ? this.monto_pago : 0,
        dias_credito: this.dias_credito,
        a_cuenta: this.a_cuenta ? this.a_cuenta : 0,
        cambio: this.cambio,
        facturado: this.facturar ? 1 : 0,
        observacion: this.observacion,
        nombre_paquete: this.paqueteSelected ? this.paqueteSelected : null,
        nro_tarjeta: this.nro_tarjeta ? this.nro_tarjeta : null,
        proforma: this.proforma,
        proforma_id: this.proforma_id,
        sucursal_id: this.sucursal_id,
      };

      if (this.medico_referencia == "medico_referencia") {
        datos = { ...datos, medico_id: this.medicoSelected };
      }

      let texto_mensaje_confirmacion = this.proforma
        ? "¿ESTÁS SEGURO DE GUARDAR LA PROFORMA?"
        : this.facturar
        ? "¿ESTÁS SEGURO DE GUARDAR LA FACTURA?"
        : "¿ESTÁS SEGURO DE GUARDAR LA VENTA?";

      if (this.productosVentas.length > 0) {
        this.$confirm.require({
          header: "CONFIRMACIÓN",
          message: texto_mensaje_confirmacion,
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "REGISTRAR",
          rejectLabel: "CANCELAR",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          closable: false,
          closeOnEscape: false,
          accept: () => {
            this.guardando = true;
            this.ventaService.crear(datos).then((res) => {
              try {
                if (has(res, "proforma")) {
                  this.$toast.add({
                    severity: "success",
                    summary: "Venta",
                    detail: "Proforma Realizada con Exito",
                    life: 3000,
                  });
                  this.guardando = false;
                  this.$router.push({
                    name: "proforma_detalle",
                    params: { id: res.proforma.id },
                  });
                  return;
                }
                if (res.status == 400) {
                  this.guardando = false;
                  this.$toast.add({
                    severity: "error",
                    summary: "VENTA",
                    detail: res.mensaje,
                    life: 60000,
                  });
                  return;
                } else {
                  this.guardando = true;
                  this.$toast.add({
                    severity: "success",
                    summary: "VENTA",
                    detail: res.mensaje,
                    life: 3000,
                  });
                  this.$router.push({
                    name: "venta_detalle",
                    params: { id: res.venta.id },
                  });

                  if (this.imprimirFactura && res.venta.facturado == 1) {
                    this.ventaService.imprimirVenta(res.venta.id);
                  }
                  if (this.imprimirVenta) {
                    this.ventaService.imprimirVenta(res.venta.id);
                  }
                }
              } catch (error) {
                this.guardando = false;
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error al guardar la venta",
                  life: 60000,
                });
              }
            });
          },
          reject: () => {
            this.$toast.add({
              severity: "info",
              summary: "Venta",
              detail: "Venta Cancelada",
              life: 3000,
            });
            this.guardando = false;
          },
        });
      }
    },
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
        nombre: this.clienteSelect.nombre ?? this.clienteSelect,
      };
    },
    activarClienteFactura() {
      this.clienteFacturaModal = true;
      this.cliente_factura = {
        estado: { label: "Activo", value: 1 },
        ci_nit: this.nit ? this.nit : null,
      };
    },
    cerrarModalClienteFactura() {
      this.clienteFacturaModal = false;
      this.cliente_factura = {};
    },
    activarMedico() {
      this.medicoModal = true;
      this.medico = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerraModalMedico() {
      this.medicoModal = false;
      this.medico = {};
    },
    cerrarModalCliente() {
      this.clienteModal = false;
      this.cliente = {};
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
      if (field == "cantidad_venta") {
        if (data.cantidad_venta > data.cantidad) {
          data.cantidad_venta = null;
          this.$toast.add({
            severity: "error",
            summary: "REVISAR STOCK DE PRODUCTO",
            detail: "No hay suficiente stock: " + data.nombre_producto,
            life: 60000,
          });
        } else {
          data["total"] = data["cantidad_venta"] * data["ultimo_precio"];
          this.calcular_subtotal();
          if (this.aplica_descuento) {
            this.calcular_agregar_des_total_venta_porcentaje();
          }
        }
      }
      if (field == "ultimo_precio" && data["cantidad_venta"] > 0) {
        data["ultimo_precio"] = data["ultimo_precio"] ?? 0;
        data["total"] = data["cantidad_venta"] * data["ultimo_precio"];
        this.calcular_subtotal();
        /* this.calcular_descuento_venta_neto(); */
        if (this.aplica_descuento == "aplica_descuento") {
          this.calcular_agregar_des_total_venta_porcentaje();
        }
      }
      if (field == "descuento_neto" && data["cantidad_venta"] > 0) {
        if (newValue > data["total"]) {
          data["descuento_neto"] = data["total"];
          data["descuento_porcentaje"] =
            (data["descuento_neto"] * 100) / data["total"] /* .toFixed(2) */;
          this.calcular_descuentos_totales_venta();
          this.$toast.add({
            severity: "error",
            summary: "REVISAR DESCUENTO",
            detail: "El descuento no puede ser mayor al total",
            life: 60000,
          });
          return;
        }
        data["descuento_porcentaje"] =
          (newValue * 100) / data["total"] /* .toFixed(2) */;
        this.calcular_descuentos_totales_venta();
      }
      if (field == "descuento_porcentaje" && data["cantidad_venta"] > 0) {
        data["descuento_neto"] = (newValue * data["total"]) / 100;
        this.calcular_descuentos_totales_venta();
      }
    },
    calcular_descuentos_totales_venta() {
      let desc_venta_neto = 0;
      /* let desc_venta_porcentaje = 0; */
      this.productosVentas.forEach((producto) => {
        desc_venta_neto += producto.descuento_neto;
        /* desc_venta_porcentaje += producto.descuento_porcentaje; */
      });
      this.descuento = desc_venta_neto;
      /* this.desc_venta_porcentaje = desc_venta_porcentaje; */
      this.desc_venta_porcentaje = (this.descuento * 100) / this.subtotal;
      this.calcular_total_venta();
    },
    calcular_subtotal() {
      this.subtotal = 0;
      this.productosVentas.forEach((product) => {
        this.subtotal += product.total;
      });
      this.total_venta = this.subtotal - this.descuento;
      if (this.descuento >= 0) {
        this.agregar_descuento_detalle_productosventas();
      }
    },
    agregar_descuento_detalle_productosventas() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_neto =
          (this.descuento * producto.total) / this.subtotal;
        producto.descuento_porcentaje =
          (producto.descuento_neto * 100) / producto.total;
      });
    },
    calcular_total_venta() {
      this.total_venta = this.subtotal - this.descuento;
    },
    calcular_descuento_venta_porcentaje() {
      this.desc_venta_porcentaje =
        (this.descuento * 100) / this.subtotal /* .toFixed(2) */;
    },
    calcular_descuento_venta_neto() {
      this.descuento =
        /* Math.round( */
        (this.desc_venta_porcentaje * this.subtotal) / 100;
      /* ); */
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        message: "¿ESTÁS SEGURO DE ELIMINAR EL PRODUCTO?",
        acceptLabel: "Si, Eliminar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.productosVentas.splice(datos.index, 1);
          this.calcular_subtotal();
          this.calcular_agregar_des_total_venta_porcentaje();
        },
      });
    },
    buscarCodProducto() {
      const datos = {
        texto: this.codProdSelected,
      };
      this.productService
        .buscarXCodProducto(datos)
        .then((response) => {
          let product = response.productos[0];
          console.log(product);

          this.codProdSelected = null;
          this.codProdListadoFiltrado = [];
        })
        .catch((error) => console.error(error));
    },
    /* buscarProducto(event) {
      setTimeout(() => {
        let _text = event.query ?? event.value;
        this.productService
          .buscarXNombreVenta({
            texto: _text,
            buscarProductoPorSelected: this.buscarProductoPorSelected,
            sucursal_id: this.sucursal_id,
            tipo_cliente_id: this.clienteSelect.tipo_cliente_id,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
            //ordeno la cantidad de stock de productos de mayor a menor
            this.productosListadoFiltrado.sort((a, b) => {
              if (a.stock[0].cantidad < b.stock[0].cantidad) {
                return 1;
              }
              if (a.stock[0].cantidad > b.stock[0].cantidad) {
                return -1;
              }
              return 0;
            });
          })
          .catch((error) => console.log(error));
      }, 200);
    }, */
    buscarProducto() {
      let datos = {
        texto: this.productoSelecionado,
        buscarProductoPorSelected: this.buscarProductoPorSelected,
        sucursal_id: this.sucursal_id,
        tipo_cliente_id: this.clienteSelect.tipo_cliente_id,
      };
      this.productService
        .buscarXNombreVenta(datos)
        .then((response) => {
          if (response.productos.length == 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se encontraron productos",
              life: 3000,
            });
            this.productosListadoFiltrado = [];
            return;
          }
          this.productosListadoFiltrado = [...response.productos];
          this.productosListadoFiltrado.sort((a, b) => {
            if (a.stock[0].cantidad < b.stock[0].cantidad) {
              return 1;
            }
            if (a.stock[0].cantidad > b.stock[0].cantidad) {
              return -1;
            }
            return 0;
          });
          if (this.permisoActivarModalProductos) {
            this.activarModalProductosFiltrados();
          }
        })
        .catch((error) => console.log(error));
    },
    cargarClientesListado(selected = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          this.clientes = response;
          // recorro los cliente y crgo al select el menor id
          this.clientes.forEach((cliente) => {
            // busco el cliente CONTROL TRIBUTARIO 99002 y lo cargo al select por defecto al cargar la pagina
            if (cliente.id == 1 && selected == 1) {
              this.clienteSelect = cliente;
            } else if (selected != null && cliente.id == selected.id) {
              this.clienteSelect = cliente;
              this.ci_nit = cliente.ci_nit;
              this.nombre_cliente = cliente.nombre;
            }
          });
        })
        .catch((error) => console.log(error));
    },
    cargarMedicosListado(medico = null) {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
        if (medico) {
          this.medicos.forEach((element) => {
            if (element.id == medico.id) {
              this.medicoSelected = element.id;
            }
          });
        }
      });
    },
    agregarDetalleVenta(data) {
      if (this.productosVentas.length > 0) {
        let existe = this.productosVentas.find(
          (producto) => producto.id == data.id
        );
        if (existe) {
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "El producto ya se encuentra en la venta",
            life: 60000,
          });
          return;
        }
      }
      if (data.ultimo_precio == 0 || data.precio_sugerido == 0) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail:
            "El producto no tiene un precio asignado: " + data.nombre_producto,
          life: 60000,
        });
        return;
      }
      if (data.ultimo_precio_compra == 0) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail:
            "El producto no tiene un precio de compra asignado: " +
            data.nombre_producto,
          life: 60000,
        });
        return;
      }

      let precio =
        data.ultimo_precio == 0 ? data.precio_sugerido : data.ultimo_precio;
      this.productosVentas.push({
        cantidad_venta: 1,
        descuento_neto: 0,
        descuento_porcentaje: 0,
        ...data,
        ultimo_precio: precio,
        total: precio * data.cantidad_venta,
        fecha_vence: data.fecha_vence ?? null,
        lote: data.lote ?? null,
      });
      // verifico si el producto no tiene stock para no agregarlo al detalle
      if (data.cantidad == 0) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El producto no tiene stock",
          life: 60000,
        });
        // hay que quitar el producto agregado al detalle
        this.productosVentas.pop();
        this.productoSelecionado = null;
        this.codProdSelected = null;
        return;
      }

      this.stockSucursales = [];
      this.fechas_vencimiento = [];
      this.productosListadoFiltrado = [];
      this.productoSelecionado = null;
      this.codProdSelected = null;

      this.calcular_subtotal();
    },
    agregarProductos(productosSeleccionados) {
      productosSeleccionados.forEach((producto) => {
        try {
          if (
            producto.stock == null ||
            producto.stock == "" ||
            producto.stock == 0
          ) {
            this.$toast.add({
              severity: "info",
              summary: "VENTA/STOCK",
              detail:
                "El producto: " + producto.descripcion + " no tiene stock",
              life: 60000,
            });
            return;
          }

          let precio_sugerido = this.obtenerPrecioSugerido2(
            producto,
            this.clienteSelect.tipo_cliente_id
          );
          this.agregarDetalleVenta({
            ...producto,
            cantidad: producto.stock[0].cantidad,
            producto_id: producto.id,
            sucursal_id: producto.stock[0].sucursal_id,
            nombre_producto: producto.descripcion,
            cantidad_venta: 1,
            descuento_neto: 0,
            descuento_porcentaje: 0,
            ultimo_precio: precio_sugerido,
            precio_sugerido: precio_sugerido,
            total: precio_sugerido * 1,
            lote: producto.fechas_vencimiento[0].lote ?? null,
            fecha_vence: producto.fechas_vencimiento[0].fecha_vence ?? null,
          });
        } catch (error) {
          console.log(error);
        }
      });
      this.cerrarModalProductoResultados();
    },
    agregarProductosPaqueteAlDetalle() {
      let datos = {
        paquete_producto_id: this.paqueteSelected,
      };
      this.paqueteProductoService
        .getPaqueteProductoById(datos)
        .then((response) => {
          /* this.paqueteSelected = null; */
          response.paqueteproductosListado.forEach((producto) => {
            let paquete_cantidad = producto.cantidad;
            let precio =
              producto.stock_para_caja_activa == null
                ? producto.producto.precio_sugerido
                : producto.stock_para_caja_activa.ultimo_precio;
            //comparo la cantidad del stock con la cantidad del paquete
            if (producto.stock_para_caja_activa.cantidad < paquete_cantidad) {
              this.$toast.add({
                severity: "error",
                summary: "Revisar stock del producto",
                detail:
                  "El producto no tiene stock suficiente: " +
                  producto.producto.descripcion,
                life: 60000,
              });
              return;
            }
            this.agregarDetalleVenta({
              ...producto,
              id: producto.producto_id,
              cantidad: producto.stock_actual,
              sucursal_id: producto.sucursal_id,
              cantidad_venta: paquete_cantidad,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              ultimo_precio: parseFloat(precio),
              precio_sugerido: parseFloat(producto.producto.precio_sugerido),
              total: parseFloat(precio * producto.cantidad),
              lote: producto.stock_para_caja_activa.lote ?? null,
              fecha_vence: producto.stock_para_caja_activa.fecha_vence ?? null,
            });
            /* this.productosVentas.push(); */

            this.calcular_subtotal();
          });
        })
        .catch((error) => console.log(error));
    },

    agregarProductosProforma() {
      this.ventaService
        .buscarProforma(this.proforma_codigo)
        .then((response) => {
          if (response.proforma != null) {
            this.proforma_id = response.proforma.id;
            let datos_cliente = {
              nombre_tipo_cliente: response.proforma.nombre_tipo_cliente,
              ci_nit: response.proforma.ci_nit,
              razon_social: response.proforma.cliente,
              cliente_id: response.proforma.cliente_id,
              nombre: response.proforma.cliente,
              id: response.proforma.cliente_id,
            };
            this.clienteSelect = datos_cliente;
            response.proforma.detalles.forEach((producto) => {
              let cantidad_proforma = producto.pivot.cantidad;
              let precio = producto.pivot.precio;
              this.agregarDetalleVenta({
                ...producto,
                id: producto.pivot.producto_id,
                producto_id: producto.pivot.producto_id,
                nombre_producto: producto.descripcion,
                cantidad: producto.stock_actual_new,
                cantidad_venta: producto.pivot.cantidad,
                descuento_neto: 0,
                descuento_porcentaje: 0,
                ultimo_precio: parseFloat(precio),
                precio_sugerido: parseFloat(producto.precio),
                total: parseFloat(precio * cantidad_proforma),
                lote: producto.pivot.lote ?? null,
                fecha_vence: producto.pivot.fecha_vence ?? null,
              });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Proforma",
              detail: "No se encontro la proforma",
              life: 60000,
            });
          }
        });
    },

    fechaActual() {
      return new Date().toLocaleDateString();
    },

    obtenerPrecioSugerido(producto, tipo_cliente_id) {
      let precio_sugerido =
        producto.ultimo_precio == 0
          ? producto.precio_sugerido
          : producto.ultimo_precio;
      if (tipo_cliente_id == 2) {
        // cliente pv2
        precio_sugerido = producto.pv2 > 0 ? producto.pv2 : precio_sugerido;
      } else if (tipo_cliente_id == 3) {
        // cliente pv3
        precio_sugerido = producto.pv3 > 0 ? producto.pv3 : precio_sugerido;
      }
      return parseFloat(precio_sugerido);
    },
    obtenerPrecioSugerido2(producto, tipo_cliente_id) {
      let precio_sugerido = producto.precio_sugerido;
      if (tipo_cliente_id == 2) {
        // cliente pv2
        precio_sugerido =
          producto.stock[0].pv2 > 0 ? producto.stock[0].pv2 : precio_sugerido;
      } else if (tipo_cliente_id == 3) {
        // cliente pv3
        precio_sugerido =
          producto.stock[0].pv3 > 0 ? producto.stock[0].pv3 : precio_sugerido;
      }
      return parseFloat(precio_sugerido);
    },
  },
  watch: {
    clienteSelect(datos) {
      if (this.facturar == true) {
        // si es factura
      } else {
        this.nit = datos.ci_nit ?? "";
        this.razon_social = datos.nombre ?? "";
      }
    },
    productoSelecionado(newVal) {
      if (newVal != null && newVal.id) {
        // Limpiar los arreglos antes de procesar el nuevo producto seleccionado
        this.fechas_vencimiento = [];
        this.stockSucursales = [];

        let precio_sugerido = this.obtenerPrecioSugerido(
          newVal.stock[0],
          this.clienteSelect.tipo_cliente_id
        );
        // Verificar la cantidad de fechas de vencimiento
        if (newVal.fechas_vencimiento.length > 1) {
          this.mostrarTablaFechasVencimiento = true;
          // Agregar el producto al arreglo fechas_vencimiento
          newVal.fechas_vencimiento.forEach((producto) => {
            this.fechas_vencimiento.push({
              ...producto,
              fabrica_nombre: newVal.fabrica_nombre,
              nombre_producto: newVal.descripcion,
              ultimo_precio: precio_sugerido,
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
          });
        } else {
          this.mostrarTablaFechasVencimiento = false;
          // Agregar el producto al arreglo stockSucursales
          newVal.stock.forEach((producto) => {
            this.stockSucursales.push({
              ...producto,
              ultimo_precio: precio_sugerido,
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              fecha_vence: producto.fecha_vence ?? null,
              lote: producto.lote ?? null,
            });
            if (producto.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
          });
        }
      }
    },
    codProdSelected(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
          });
        }
      }
    },
    total_venta() {
      if (this.tipo_pagoSelect == 1) {
        this.efectivo = this.total_venta;
      } else {
        this.a_cuenta = 0;
        this.efectivo = 0;
      }
      if (this.metodo_pagoSelect == 1) {
        this.monto_pago = 0;
      } else {
        this.monto_pago = this.total_venta;
      }
    },
    a_cuenta() {
      if (this.a_cuenta > this.total_venta) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El monto a cuenta no puede ser mayor al total de la venta",
          life: 60000,
        });
        this.a_cuenta = 0;
        return;
      }
      this.efectivo = this.a_cuenta;
    },
    monto_pago() {
      if (this.tipo_pagoSelect == 2) {
        this.efectivo = this.a_cuenta;
      } else {
        this.efectivo = this.total_venta - this.monto_pago;
      }
    },
    /* tipo_pagoSelect() {
      if (this.tipo_pagoSelect == 1) {
        this.a_cuenta = 0;
        this.monto_pago = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
        this.a_credito_check = false;
      } else {
        this.dias_credito = 30;
        this.a_cuenta = 0;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = this.a_cuenta;
      }
    }, */
    metodo_pagoSelect() {
      if (this.metodo_pagoSelect == 1) {
        this.monto_pago = 0;
        this.efectivo = this.total_venta - this.a_cuenta;
        this.metodo_pago_check = false;
        this.nro_tarjeta = null;
      } else if (this.metodo_pagoSelect == 2 && this.a_credito_check) {
        this.monto_pago = 0;
        this.efectivo = 0;
      } else {
        this.efectivo = 0;
        this.monto_pago = this.total_venta - this.efectivo;
      }
    },
    metodo_pago_check() {
      if (this.metodo_pago_check) {
        this.metodo_pagoSelect = 6;
        this.efectivo = 0;
        this.monto_pago = this.total_venta - this.efectivo;
      } else {
        this.metodo_pagoSelect = 1;
        this.metodo_pago_check = false;
        this.monto_pago = 0;
        this.efectivo = this.total_venta - this.a_cuenta;
        this.nro_tarjeta = "";
      }
    },
    a_credito_check() {
      if (this.a_credito_check) {
        this.tipo_pagoSelect = 2;
        this.dias_credito = 30;
        this.a_cuenta = null;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = this.a_cuenta;
      } else {
        this.tipo_pagoSelect = 1;
        this.a_credito_check = false;
        this.a_cuenta = null;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
        this.monto_pago = 0;
      }
    },
    aplica_descuento() {
      if (this.aplica_descuento) {
        this.desc_venta_porcentaje = 5;
        this.calcular_agregar_des_total_venta_porcentaje();
      } else {
        this.desc_venta_porcentaje = 0;
      }
    },
    facturar() {
      this.nit = "";
      this.razon_social = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #747474;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 0.5em;
  border: 1px solid #002236;
}
.precio {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
//quiero que esta  clase no tenga el  fondo de plomo
.p-inputgroup-addon {
  background-color: #ffffff;
  font-weight: bold;
  color: #000000;
  border: none;
}
</style>
